export const STRINGS = {
  phoneNumber: "Phone Number",
  password: "Password",
  loginToYourAccount: "Login to your account.",
  login: "Log in",
  dontHaveAnAccount: "Don't have an account? ",
  signUp: "Sign up",
  havingTroubleLoggingIn: "Having trouble logging in? ",
  clickHereToResetPassword: "Click here to reset your password",
  confirmPassword: "Confirm Password",
  name: "Name",
  alreadyHaveAnAccount: "Already have an account? ",
  startSetUp: "Configure your account",
  enterAccountCredentials: "Enter your account credentials below",
  continue: "Continue",
  weHaveSent: "We have sent a code to your phone number. Please enter it below",
  youAlreadyRegistered: "You have already registered... please log in.",
  youHaveBeenInvitedTo: "You have been invited to ",
  loadingYourInvitation: "Loading your invitation...",
  billing: "Billing",
  payForService: "Pay for your service here",
  accountStatus: "Account Status",
  yourLastActivation: "Your last activation date is ",
  yourAccountIs: "Your account is ",
  expired: "EXPIRED",
  active: "ACTIVE",
  costBreakdown: "Cost Breakdown",
  serviceCost: "Service cost",
  transactionFee: "Transaction Fee",
  totalCost: "Total cost: ",
  proceedToPayment: "Proceed to payment",
  hiThere: "Hi there! 👋",
  weAreSorryToHear:
    "We're sorry to hear that your service is expired.{\"\\n\"}We hope you'll be able to resume your subscription soon!",
  ifYouHaveQuestions:
    "If you have any questions or concerns, please don't hesitate to reach out to us.",
  makePayment: "Make Payment",
  pay: "Pay",
  configuration: "Configuration",
  changeYourSystemSettings: "Change your system settings",
  successfullyUpdatedConfig: "Successfully update configuration",
  unableUpdateConfig: "Unable to update configuration item",
  notAbleFinalizeConfig: "Not able to finalize configuration",
  back: "Back",
  minBlockHour: "📦 Min Hours Per Block",
  chooseMinAmountHours:
    "Choose the minimum number of hours per block you would like to accept",
  startTime: "🕕 Start Time",
  selectHourStart: "Select the hour you would like to start working at.",
  endTime: "🕥 End Time",
  selectHourStop: "Select the hour you would like to stop working at.",
  timeBuffer: "⏰ Time Buffer",
  selectTimeBuffer:
    "Select the minimum time you would like to have until a block starts.",
  deliveryLocation: "🚚 Delivery Location",
  locationAlreadyExists: "You already have this location",
  enterDeliveryLocations:
    "Enter locations where you would like to deliver packages to. Remember to spell them correctly or we won't grab any blocks for you.",
  errorGettingLocations:
    "Error getting locations - please refresh to try again",
  locations: "📍 Locations",
  selectLocations: "Select the locations where you would like to work.",
  home: "Home",
  viewYourStats: "View your stats and control your service",
  accepted: "Accepted",
  rejected: "Rejected",
  unableToChangeStatus: "Unable to change system status",
  systemControl: "System Control",
  pressPowerButton: "Press power button to turn system on or off",
  status: "Status: ",
  on: "On",
  off: "Off",
  topStats: "Top Stats",
  blocks: "Blocks ",
  thisWeek: " this week",
  starts: "Starts - ",
  reason: "Reason - ",
  preferences: "Preferences",
  changeUserPreferences: "Change your user preferences",
  contactInfo: "Contact Info",
  contactMeAt: "Contact me at",
  thisIsWhereWeNotifyYou:
    "This is where you will be notified when we grab a block for you",
  update: "Update",
  incorrectUsernameOrPassword: "Incorrect username or password",
  errorLoadingInvites: "Error loading invites",
  inviteSentSuccessfully: "Invite sent successfully",
  errorSendingInvite: "Error sending invite",
  referAFriend: "Refer a friend",
  startYourWeekWithAFriend: "Start your week with a friend!",
  ifTheyPay: "If they pay, ",
  getAWeekFree: " get a free week.",
  you: "you",
  itIsThatSimple: "It's that simple.",
  sendInvite: "Send Invite",
  yourFriendPhoneNumber: "Your friend's phone number ",
  previewMessage: "Preview message",
  weAreExcitedMessage:
    " Hey there!\nWe're so excited to have you join us at APP_NAME!\nUSERNAME has invited you to join us. Please visit this link to register.",
  send: "Send",
  invitesYouSent: "Invites you have sent",
  youInvited: "You invited",
  haveTheyPaid: "Have they paid",
  resetYourPassword: "Reset Your Password",
  enterVerificationCode: "Enter verification code",
  enterNewPassword: "Enter new password",
  changePassword: "Change Password",
  distanceToLocation: "📍 Location",
  distanceToLocationHelperText: "Select pick up location",
  pickupLocation: "Where to pick up from",
  maxMiles: "Maximum miles to drive",
};
