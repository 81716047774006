import HomeWidgetContainer from "./HomeWidgetContainer";
import {Button, ButtonGroup, Divider, IconButton, Stack, Text, useToast} from "@chakra-ui/react";
import {useUserContext} from "../context/UserContext";
import config from '../assets/config.json';
import {BsPower} from "react-icons/bs";
import {patchUser} from "../services/UserService";
import {useLoader} from "../utils/hooks";
import {useTranslationContext} from "../context/TranslationContext";


function SystemControl() {
  const { STRINGS } = useTranslationContext();
  const { user, load } = useUserContext();
  const toast = useToast();
  const loader = useLoader(false)


  async function toggleStartState() {
    loader.start();
    const currValue = user[config.setupOptions.mainKey].start;

    const options = {
      ...user[config.setupOptions.mainKey],
      start: !currValue
    };

    try {
      await patchUser({
        userId: user.userId,
        body: {
          [config.setupOptions.mainKey]: options
        }
      })
      await load();
      loader.stop();
    } catch(e) {
      loader.stop();
      toast({
        status: "error",
        title: "System Control",
        description: STRINGS.unableToChangeStatus
      });
    }

  }

  return (
    <HomeWidgetContainer
      label={STRINGS.systemControl}
      helperText={STRINGS.pressPowerButton}
    >
      <IconButton isLoading={loader.isLoading()} isDisabled={loader.isLoading()} onClick={toggleStartState} colorScheme={user[config.setupOptions.mainKey].start ? "green" : "red"} variant={"solid"} position={"absolute"} top={"5px"} right={"10px"}>
        <BsPower fontSize={"20px"} />
      </IconButton>
      <Stack mt={2}>
        <Text fontSize={"xl"}>
          {STRINGS.status}<Text fontSize={"xl"} as={"span"} fontWeight={"bold"}>{user[config.setupOptions.mainKey].start ? STRINGS.on : STRINGS.off}</Text>
        </Text>
      </Stack>
    </HomeWidgetContainer>
  )
}


export default SystemControl;
