import {createContext, useContext, useEffect, useState} from "react";
import {useLoader} from "../utils/hooks";
import {getUser} from "../services/UserService";

const UserContext = createContext();

export function useUserContext() {
  return useContext(UserContext);
}

export default function UserContextProvider({ children }) {
  const [user, setUser] = useState(null);
  const loader = useLoader(true);

  async function load() {
    try {
      const response = await getUser();
      setUser(response);
      console.log("response: ", response);
      loader.stop();
      return response;
    } catch(e) {
      console.log("ERROR getting user");
      loader.stop();
    }
  }

  useEffect(() => {
    load()
  }, [])

  return (
    <UserContext.Provider value={{ user, loading: loader.isLoading(), load }}>
      {children}
    </UserContext.Provider>
  )
}
