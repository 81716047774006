import PageWrapper from "../components/PageWrapper";
import {Button, CircularProgress, Divider, Flex, Spinner, Stack, Text} from "@chakra-ui/react";
import {useUserContext} from "../context/UserContext";
import {getNestedValue} from "../common/functions";
import config from '../assets/config.json'
import {useEffect, useState} from "react";
import ExpiredDialog from "../components/ExpiredDialog";
import {getPaymentAmout, getPaymentIntent} from "../services/BillingService";
import {useLoader} from "../utils/hooks";
import moment from "moment-timezone";
import PaymentForm from "../components/PaymentForm";
import TransactionLoader from "../components/TransactionLoader";
import {useTranslationContext} from "../context/TranslationContext";

function BillingPage() {
  const {user} = useUserContext();
  const { STRINGS } = useTranslationContext();


  const isExpired = !getNestedValue(user, config.goodStandingKey)

  const [showExpiredDialog, setShowExpiredDialog] = useState(isExpired && moment(user.createDate).isBefore(moment().subtract("day", 7)));

  const [billingData, setBillingData] = useState(null);
  const loader = useLoader(true);
  const paymentIntentLoader = useLoader(false);

  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [paymentIntent, setPaymentItent] = useState(null);

  const [showTransactionLoader, setShowTransactionLoader] = useState(window.location.href.includes('transaction-complete=true'));


  async function loadData() {
    try {
      const response = await getPaymentAmout();
      console.log("payment amount: ", response);
      setBillingData(response);
      loader.stop();
    } catch (e) {
      console.log("ERROR getting payment amoutn: ", e);
      loader.stop();
    }
  }

  useEffect(() => {
    loadData();
  }, [])

  async function onMakePaymentIntentClick() {
    try {
      paymentIntentLoader.start();
      const response = await getPaymentIntent();
      setPaymentItent(response);
      setShowPaymentForm(true);
      paymentIntentLoader.stop();
    } catch(e) {
      console.log("ERROR: ")
      paymentIntentLoader.stop();
    }
  }


  if (showTransactionLoader) {
    return (
      <TransactionLoader />
    )
  }

  return (
    <PageWrapper padding={"32px 32px 48px 32px"}>
      <ExpiredDialog open={showExpiredDialog} onClose={() => setShowExpiredDialog(false)}/>
      <Text fontSize={"2xl"} fontWeight={"medium"}>
        {STRINGS.billing}
      </Text>
      <Text>
        {STRINGS.payForService}
      </Text>
      <Flex mt={8} position={"relative"} boxShadow={"sm"} bg={"white"} borderRadius={8} pl={4} pt={6} pb={8} pr={4}
            flexDirection={"column"}>
        <Text fontSize={"2xl"} fontWeight={"semibold"}>
          {STRINGS.accountStatus}
        </Text>
        <Text mt={4}>
          {STRINGS.yourLastActivation}{moment(user.lastPaymentDate).format("MMM DD, YYYY")}
        </Text>
        <Text>
          {STRINGS.yourAccountIs}<Text fontSize={"2xl"} fontWeight={"bold"} as={"span"}>{isExpired ? STRINGS.expired + " ❌" : STRINGS.active + " ✅"}</Text>
        </Text>
      </Flex>
      <Flex mt={8} position={"relative"} boxShadow={"sm"} bg={"white"} borderRadius={8} pl={4} pt={6} pb={8} pr={4}
            flexDirection={"column"}>
        {loader.isLoading() && (
          <Spinner size={"lg"} alignSelf={'center'}/>
        )}
        {!loader.isLoading() && (
          <>
            {!showPaymentForm && (
              <>
                <Text fontSize={"2xl"} fontWeight={"semibold"}>
                  {STRINGS.costBreakdown}
                </Text>
                <Stack mt={6}>
                  <Flex justifyContent={"space-between"}>
                    <Text>
                      {STRINGS.serviceCost}
                    </Text>
                    <Text>
                      ${billingData.details.subTotal}
                    </Text>
                  </Flex>
                  <Divider/>
                  <Flex justifyContent={"space-between"}>
                    <Text>
                      {STRINGS.transactionFee}
                    </Text>
                    <Text>
                      ${billingData.details.bankFee}
                    </Text>
                  </Flex>
                  <Divider borderColor={"gray.400"}/>
                  <Text alignSelf={"flex-end"}>
                    {STRINGS.totalCost}<Text fontSize={"3xl"} fontWeight={"bold"} as={"span"}>${billingData.amountDue}</Text>
                  </Text>
                </Stack>
                <Button onClick={onMakePaymentIntentClick} isLoading={paymentIntentLoader.isLoading()} isDisabled={paymentIntentLoader.isLoading()} mt={4}>
                  {STRINGS.proceedToPayment}
                </Button>
              </>
            )}
            {showPaymentForm && (
                <PaymentForm paymentIntent={paymentIntent} total={billingData.amountDue} />
            )}
          </>
        )}
      </Flex>

    </PageWrapper>
  )
}

export default BillingPage;
