import ConfigOptionWrapper from "./ConfigOptionWrapper";
import {Select} from "@chakra-ui/react";
import config from '../assets/config.json';
import moment from 'moment-timezone';
import {useUserContext} from "../context/UserContext";
import {useTranslationContext} from "../context/TranslationContext";


function StartTime({onChange}) {
  const {user} = useUserContext();
  const { STRINGS } = useTranslationContext();
  return (
    <ConfigOptionWrapper label={STRINGS.startTime} helperText={STRINGS.selectHourStart}>
      <Select name={"startHour"} onChange={(e) => onChange(e, 1)}
              value={user[config.setupOptions.mainKey]?.startHour || 0}>
        {config.startHourTimes.map(time => (
          <option value={time}>{moment().set('hour', time).set('minute', 0).format("h A")}</option>
        ))}
      </Select>
    </ConfigOptionWrapper>
  )
}

export default StartTime;
