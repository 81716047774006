import PageWrapper from "../components/PageWrapper";
import { Text } from '@chakra-ui/react';
import config from '../assets/config.json';
import Credentials from '../components/Credentials';
import Configuration from "../components/Configuration";
import { useSteps } from '../utils/hooks';
import {useTranslationContext} from "../context/TranslationContext";

function SetUpPage() {
  const steps = useSteps(0, config.setupSteps.length);
  const { STRINGS } = useTranslationContext();

  return (
    <PageWrapper padding={8}>
      <Text textAlign={"center"} width={"100%"} alignSelf={"center"} maxWidth={500} fontWeight={"semibold"} fontSize={"3xl"} >
        {STRINGS.startSetUp}
      </Text>
      {config.setupSteps[steps.getCurrentStep()] === "credentials" && (
        <Credentials onContinue={steps.nextStep} />
      )}
      {config.setupSteps[steps.getCurrentStep()] === "configuration" && (
        <Configuration />
      )}
    </PageWrapper>
  )
}

export default SetUpPage;
