import {extendTheme, withDefaultColorScheme} from '@chakra-ui/react';
import {mode} from "@chakra-ui/theme-tools";
import appConfig from '../assets/config.json';


const styles = {
  global: (props) => ({
    body: {
      bg: mode(appConfig.bodyColor, appConfig.bodyColor)(props),
    },
  }),
};

// 2. Add your color mode config
const config = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

const Input = {
  baseStyle: {
    field: {
      // color: 'gray.200',
    },
  },
  defaultProps: {
    focusBorderColor: appConfig.inputOutlineColor,
  },
}

const Select = {
  baseStyle: {
    field: {
      // color: 'gray.200',
    },
  },
  defaultProps: {
    focusBorderColor: appConfig.inputOutlineColor,
  },
}



const components = {
  Input,
  Select
}

//     // 3. extend the theme
const theme = extendTheme(withDefaultColorScheme({  colorScheme: appConfig.colorScheme }), { config, styles, components });

export default theme;
