import {Stack, Text} from "@chakra-ui/react";


function ConfigOptionWrapper({ children, label, helperText }) {
  return (
    <Stack borderRadius={8} pl={4} pr={4} pt={6} pb={8} bg={"white"}>
      <Stack spacing={0}>
        <Text fontWeight={"semibold"} fontSize={"lg"}>
          {label}
        </Text>
        <Text fontSize={"sm"}>
          {helperText}
        </Text>
      </Stack>
      {children}
    </Stack>
  )
}

export default ConfigOptionWrapper;
