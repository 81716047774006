import {Flex, Stack, Text} from "@chakra-ui/react";

function HomeWidgetContainer({
  children,
  label,
  helperText
                             }) {
  return (
    <Flex position={"relative"} boxShadow={"sm"} bg={"white"} borderRadius={8} pl={4} pt={6} pb={8} pr={4} flexDirection={"column"}>
      <Stack spacing={0}>
        <Text fontSize={"md"} fontWeight={"medium"}>
          {label}
        </Text>
        <Text fontSize={"xs"}>
          {helperText}
        </Text>
      </Stack>
      {children}
    </Flex>
  )
}

export default HomeWidgetContainer;
