import { Button } from "@chakra-ui/react";


export default function AdvancedConfigurationToggle({
    show, toggle
}) {
    return (
        <>
        {show ? (
            <Button colorScheme="gray" onClick={toggle}>
                Hide Advanced Options
            </Button>
        ) : (
            <Button colorScheme="gray" onClick={toggle}>
                Show Advanced Options
            </Button>
        )}
        </>

    );
}