import {Button, Flex, FormControl, FormLabel, Input, InputGroup, InputLeftElement, Stack, Text, useToast} from "@chakra-ui/react";
import { Link } from 'react-router-dom'
import config from '../assets/config.json';
import {useFormInput, useLoader} from "../utils/hooks";
import PageWrapper from "../components/PageWrapper";

import {Auth} from "aws-amplify";
import {useUserContext} from "../context/UserContext";
import {cleanPhoneNumber} from "../common/functions";
import {useTranslationContext} from "../context/TranslationContext";


function LoginPage() {
  const { STRINGS } = useTranslationContext();
  const phoneNumber = useFormInput("", (val) => {
    val = val.replace(/ /gm, '');
    let num = `${val.substring(0, 3)} ${val.substring(3, 6)} ${val.substring(6, val.length)}`;

    return num.trim();
  });
  const password = useFormInput();
  const loader = useLoader(false);
  const toast = useToast();
  const { load } = useUserContext();

  async function onLoginClick() {
    try {
      loader.start();
      await Auth.signIn(cleanPhoneNumber(phoneNumber.value), password.value);
      await load();
      loader.stop();
    } catch (error) {
      loader.stop();
      toast({
        status: "error",
        title: "Login Error",
        description: error.message
      })
    }
  }

  return (
    <>
      <PageWrapper>
        <Link to={"/"}>
          <Text mt={16} fontSize={"3xl"} fontWeight={"bold"} textAlign={"center"}>{config.appName}</Text>
        </Link>
        <Flex boxShadow={"lg"} mt={6} maxW={400} width={"100%"} alignSelf={"center"} p={8} borderRadius={8} bg={"white"}
              pt={12} pb={12} flexDirection={"column"}>
          <Text textAlign={"center"} fontSize={"xl"} fontWeight={"semibold"}>{STRINGS.loginToYourAccount}</Text>
          <Stack spacing={4} mt={8}>
            <FormControl onKeyDown={(e) => e.key === "Enter" && onLoginClick()}>
              <FormLabel>{STRINGS.phoneNumber}</FormLabel>
              <InputGroup>
              <InputLeftElement>
              <Text>+1</Text>
              </InputLeftElement>
              <Input type={"phoneNumber"} {...phoneNumber} placeholder={"555 555 5555"}/>
              </InputGroup>
            </FormControl>
            <FormControl onKeyDown={(e) => e.key === "Enter" && onLoginClick()}>
              <FormLabel>{STRINGS.password}</FormLabel>
              <Input {...password} type={"password"} placeholder={"*********"}/>
            </FormControl>
            <Button isDisabled={!phoneNumber.value || !password.value || loader.isLoading()}
                    isLoading={loader.isLoading()}
                    onClick={onLoginClick}>
              {STRINGS.login}
            </Button>
            {config.allowRegister && (
              <Text fontSize={"sm"} textAlign={"center"}>
                {STRINGS.dontHaveAnAccount} <Link to={"/register"}><Text cursor={"pointer"} textDecoration={"underline"}
                                                                         as={"span"}>{STRINGS.signUp}</Text></Link>
              </Text>
            )}
          </Stack>
        </Flex>
        {config.allowPasswordReset && (
          <Text fontSize={"sm"} textAlign={"center"} mt={6}>
            {STRINGS.havingTroubleLoggingIn}<Link to={"/forgot-password"}><Text cursor={"pointer"}
                                                                                  textDecoration={"underline"}
                                                                                  as={"span"}>{STRINGS.clickHereToResetPassword}</Text></Link>
          </Text>
        )}
      </PageWrapper>
    </>
  )
}

export default LoginPage;
