import PageWrapper from "./PageWrapper";
import {CircularProgress, Flex, Text, useToast} from "@chakra-ui/react";
import config from "../assets/config.json";
import {useUserContext} from "../context/UserContext";
import {useEffect, useRef, useState} from "react";
import {getNestedValue} from "../common/functions";
import {useNavigate} from "react-router-dom";


function TransactionLoader() {
  const { user, load } = useUserContext();
  const navigate = useNavigate();
  const interval = useRef();
  const toast = useToast();
  const [activated, setActivated] = useState(false);

  useEffect(() => {

    interval.current = setInterval(async () => {
      const updated = await load();
      if (getNestedValue(updated, config.goodStandingKey)) {
        if (interval.current) {
          clearInterval(interval.current);
          interval.current = false;
        }

        toast({
          status: "success",
          title: "Billing",
          description: "Your account has been activated!"
        })

        setActivated(true);

        setTimeout(() => {
          navigate("/home");
        }, 5000);
      }
    }, 5000);


    return () => {
      if (interval.current) {
        clearInterval(interval.current);
        interval.current = false;
      }
    }
  }, [])
  return (
    <PageWrapper padding={"32px 32px 48px 32px"}>
      <Text fontSize={"2xl"} fontWeight={"medium"}>
        Billing
      </Text>
      <Text>
        Pay for your service here
      </Text>
      <Flex mt={8} position={"relative"} boxShadow={"sm"} bg={"white"} borderRadius={8} pl={4} pt={6} pb={8} pr={4}
            flexDirection={"column"}>
        <CircularProgress alignSelf={"center"} color={config.inputOutlineColor} size={32} isIndeterminate/>
        <Text fontSize={"2xl"} fontWeight={"semibold"} textAlign={"center"} mt={4}>
          Processing order...
        </Text>
        {activated && (
          <Text mt={4} alignSelf={"center"}>
            Your account has been activated. Refreshing in 5 seconds...
          </Text>
        )}
      </Flex>
    </PageWrapper>
  )
}

export default TransactionLoader;
