import PageWrapper from "../components/PageWrapper";
import { Button, ButtonGroup, Divider, Stack, Text } from "@chakra-ui/react";
import SystemControl from "../components/SystemControl";
import { useEffect, useState } from "react";
import config from "../assets/config.json";
import { useUserContext } from "../context/UserContext";
import { getOffers } from "../services/OfferService";
import StatsContainer from "../components/StatsContainer";
import { useTranslationContext } from "../context/TranslationContext";

function HomePage() {
  const [blockType, setBlockType] = useState("got");

  const [offers, setOffers] = useState([]);
  const { user } = useUserContext();
  const { STRINGS } = useTranslationContext();

  console.log("offers: ", offers);
  async function loadData() {
    try {
      const response = await getOffers(user.userId);
      setOffers(response);
      console.log("offers response: ", response);
      setOffers(response);
    } catch (e) {
      console.log("ERROR loadign offers: ", e);
    }
  }

  useEffect(() => {
    loadData();
  }, []);
  return (
    <PageWrapper padding={"32px 32px 48px 32px"}>
      <Text fontSize={"2xl"} fontWeight={"medium"}>
        {STRINGS.home}
      </Text>
      <Text>{STRINGS.viewYourStats}</Text>
      <Stack mt={4} spacing={8}>
        <SystemControl />
        <Stack display={"flex"} alignItems={"center"} direction={"row"}>
          <Divider borderColor={"black.400"} />
          <ButtonGroup isAttached colorScheme={config.colorScheme}>
            <Button
              onClick={() => setBlockType("got")}
              variant={blockType === "got" ? "solid" : "outline"}
            >
              {STRINGS.accepted}
            </Button>
            <Button
              onClick={() => setBlockType("dislikes")}
              variant={blockType === "dislikes" ? "solid" : "outline"}
            >
              {STRINGS.rejected}
            </Button>
          </ButtonGroup>
          <Divider borderColor={"black.400"} />
        </Stack>
        <StatsContainer blockType={blockType} offers={offers} />
      </Stack>
    </PageWrapper>
  );
}

export default HomePage;
