import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text
} from "@chakra-ui/react";
import {useTranslationContext} from "../context/TranslationContext";


function ExpiredDialog({ onClose, open }) {
  const { STRINGS } = useTranslationContext();
  return (
    <Modal closeOnOverlayClick={false} size={"lg"} isCentered={true} isOpen={open} onClose={onClose}>
      <ModalOverlay />
      <ModalContent m={4}>
        <ModalCloseButton />
        <ModalHeader>
          {STRINGS.hiThere}
        </ModalHeader>
        <ModalBody>
          <Text whiteSpace={"break-spaces"} fontSize={"md"} textAlign={"center"}>
            {STRINGS.weAreSorryToHear}
          </Text>
          <Text fontSize={"md"} textAlign={"center"} mt={4}>
            {STRINGS.ifYouHaveQuestions}
          </Text>
        </ModalBody>
        <ModalFooter />
      </ModalContent>
    </Modal>
  )
}


export default ExpiredDialog;
