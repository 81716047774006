import HttpService from "./HttpService";
import config from '../assets/config.json'

export async function getPaymentAmout() {
  try {
    const response = await HttpService.get(config.getPaymentAmountApi);
    return response.data;
  } catch(e) {
    throw e;
  }
}

export async function getPaymentIntent() {
  try {
    const response = await HttpService.get(config.getPaymentIntentApi);
    return response.data;
  } catch(e) {
    throw e;
  }
}
