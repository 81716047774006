import PageWrapper from "../components/PageWrapper";
import {Link, useNavigate} from "react-router-dom";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  PinInput,
  PinInputField,
  Text,
  useToast
} from "@chakra-ui/react";
import config from "../assets/config.json";
import {useFormInput, useLoader} from "../utils/hooks";
import {cleanPhoneNumber, validatePhoneNumber} from "../common/functions";
import {confirmChangePassword, requestForgotPassword, verifyCode} from "../services/UserService";
import {useState} from "react";
import {INPUT_PLACEHOLDERS} from "../common/convertions";
import {useTranslationContext} from "../context/TranslationContext";

function ForgotPasswordPage() {
  const { STRINGS } = useTranslationContext();
  const phoneNumber = useFormInput("", (val) => {
    val = val.replace(/ /gm, '');
    let num = `${val.substring(0, 3)} ${val.substring(3, 6)} ${val.substring(6, val.length)}`;

    return num.trim();
  });

  const [step, setStep] = useState(0);
  const toast = useToast();
  const loader = useLoader(false);
  const [code, setCode] = useState("");
  const password = useFormInput("");
  const confirmPassword = useFormInput("");
  const navigate = useNavigate();

  async function onChangePasswordClick() {
    try {
      loader.start();
      const response = await confirmChangePassword(cleanPhoneNumber(phoneNumber.value).replace("+", "").trim(), code, password.value);
      console.log("RESPONSE: ", response);
      toast({
        status: "success",
        title: "Change Password",
        description: "Successfully changed your password"
      });

      setTimeout(() => {
        navigate("/login");
      }, 1000)

    } catch(e) {
      loader.stop();
      toast({
        title: "Verification",
        status: "error",
        description: "Unable to change your password"
      });
    }
  }

  async function onCodeVerification() {
    try {
      loader.start();
      const response = await verifyCode(cleanPhoneNumber(phoneNumber.value).replace("+", "").trim(), code);
      console.log("RESPONSE: ", response);
      setStep(step + 1);
      loader.stop();
    } catch(e) {
      loader.stop();
      toast({
        title: "Verification",
        status: "error",
        description: "Unable to verify code"
      });
    }
  }

  async function onResetPassword() {
    try {
      loader.start();
      const cleanPhone = cleanPhoneNumber(phoneNumber.value).replace("+", "").trim();
      const r = await requestForgotPassword(cleanPhone);

      console.log("r: ", r);
      setStep(1);
      loader.stop();
    } catch(e) {
      console.log("ERROR sending message: ", e);
      loader.stop();
      toast({
        status: "error",
        title: "ForgotPasswordError",
        description: e.message
      })
    }
  }

  return (
    <PageWrapper>
      <Link to={"/"}>
        <Text mt={16} fontSize={"3xl"} fontWeight={"bold"} textAlign={"center"}>{config.appName}</Text>
      </Link>
      <Flex boxShadow={"lg"} mt={6} maxW={400} width={"100%"} alignSelf={"center"} p={8} borderRadius={8} bg={"white"}
            pt={12} pb={12} flexDirection={"column"}>
        {step === 0 && (
          <>
            <Text textAlign={"center"} fontSize={"xl"} fontWeight={"semibold"}>{STRINGS.resetYourPassword}</Text>
            <FormControl mt={4} onKeyDown={() => {}}>
              <FormLabel>{STRINGS.phoneNumber}</FormLabel>
              <InputGroup>
              <InputLeftElement>
              <Text>+1</Text>
              </InputLeftElement>
              <Input type={"phoneNumber"} {...phoneNumber} placeholder={"555 555 5555"}/>
              </InputGroup>
            </FormControl>
            <Button isLoading={loader.isLoading()} isDisabled={!validatePhoneNumber(phoneNumber.value)} onClick={onResetPassword} mt={6}>
              Reset Password
            </Button>
          </>
        )}
        {step === 1 && (
          <>
            <Text textAlign={"center"} fontSize={"xl"} fontWeight={"semibold"}>{STRINGS.enterVerificationCode}</Text>
              <HStack mt={4} alignSelf={"center"}>
                <PinInput value={code} onChange={setCode}>
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                </PinInput>
              </HStack>
            <Button onClick={onCodeVerification} isLoading={loader.isLoading()} isDisabled={code.length !== 4} size={"md"} mt={4}>
              Verify
            </Button>
          </>
        )}
        {step === 2 && (
          <>
          <Text textAlign={"center"} fontSize={"xl"} fontWeight={"semibold"}>{STRINGS.enterNewPassword}</Text>
            <FormControl mt={4} onKeyDown={() => {}}>
              <FormLabel>{STRINGS.password}</FormLabel>
              <Input type={"password"} {...password} placeholder={INPUT_PLACEHOLDERS.password}/>
            </FormControl>
            <FormControl mt={4} onKeyDown={() => {}}>
              <FormLabel>{STRINGS.confirmPassword}</FormLabel>
              <Input type={"password"} {...confirmPassword} placeholder={INPUT_PLACEHOLDERS.password}/>
            </FormControl>
            <Button onClick={onChangePasswordClick} isLoading={loader.isLoading()} isDisabled={!password.value || !confirmPassword.value || password.value !== confirmPassword.value} size={"md"} mt={4}>
              {STRINGS.changePassword}
            </Button>
          </>
        )}
          <Link to={"/login"}>
          <Text textAlign={"center"} cursor={"pointer"} textDecoration={"underline"}  fontSize={"xs"} mt={4}>
            Go back to login
          </Text>
        </Link>
      </Flex>
    </PageWrapper>
  )
}

export default ForgotPasswordPage;
