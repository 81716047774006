import ConfigOptionWrapper from "./ConfigOptionWrapper";
import {Select, Stack, Text} from "@chakra-ui/react";
import config from '../assets/config.json';
import {useUserContext} from "../context/UserContext";
import {useTranslationContext} from "../context/TranslationContext";



function MinBlockHours({
  onChange
                       }) {
  const { user } = useUserContext();
  const { STRINGS } = useTranslationContext();

  return (
    <ConfigOptionWrapper label={STRINGS.minBlockHour} helperText={STRINGS.chooseMinAmountHours}>
    <Select name={"minBlockHours"} onChange={(e) => onChange(e, 1)} value={user[config.setupOptions.mainKey]?.minBlockHours || 1}>
        {config.minBlockHoursOptions.map(minOption => (
          <option key={minOption} value={minOption}>
            {minOption} hours
          </option>
        ))}
      </Select>
    </ConfigOptionWrapper>
  )
}

export default MinBlockHours;
