import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  Flex,
  Text,
  Stack,
  Divider
} from "@chakra-ui/react";
import config from '../assets/config.json';
import {useNavigate} from "react-router-dom";
import {IoMdSettings} from "react-icons/io";
import {BiLogOut} from "react-icons/bi";
import {Auth} from "aws-amplify";

function Sidebar({onClose, open}) {
  const navigate = useNavigate();
  return (
    <Drawer placement={"left"} onClose={onClose} isOpen={open}>
      <DrawerOverlay/>
      <DrawerContent>
        <DrawerHeader>{config.appName}</DrawerHeader>
        <DrawerBody display={"flex"} flexDirection={"column"} justifyContent={"space-between"}>
          <Stack spacing={2}>
            {config.navOptions.map(navOption => (
              <>
                <Flex onClick={() => {
                  navigate(navOption.path)
                  onClose()
                }} cursor={"pointer"} _hover={{
                  background: "gray.50"
                }} borderRadius={8} p={2}
                      background={window.location.pathname.includes(navOption.path) ? "gray.100" : "white"}>
                  <Text fontSize={"lg"}>
                    {navOption.label}
                  </Text>
                </Flex>
              </>
            ))}
          </Stack>
          <Stack>
            <Divider />
            <Flex onClick={() => {
              onClose();
              navigate("/preferences");
            }} cursor={"pointer"} _hover={{
              background: "gray.50"
            }} borderRadius={8} p={2} alignItems={"center"}>
              <IoMdSettings color={"gray"} fontSize={"24px"}/>
              <Text fontSize={"lg"} ml={4}>
                Preferences
              </Text>
            </Flex>
            <Flex onClick={() => {
              Auth.signOut();
              onClose();
              window.location.href = "/login"
            }} cursor={"pointer"} _hover={{
              background: "gray.50"
            }} borderRadius={8} p={2}>
              <BiLogOut fontSize={"24px"} color={"gray"} />
              <Text ml={4} fontSize={"lg"}>
                Log out
              </Text>
            </Flex>
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default Sidebar;
