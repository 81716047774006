import {createContext, useContext, useState} from "react";
import {STRINGS_ES} from "../common/strings_es";
import {STRINGS} from "../common/strings";

const TranslationContext = createContext();

export function useTranslationContext() {
  return useContext(TranslationContext);
}

export default function TranslationContextProvider({ children }) {
  const [file, setFile] = useState(STRINGS_ES);

  function setToEnglish() {
    setFile(STRINGS);
  }

  function setToSpanish() {
    setFile(STRINGS_ES);
  }

  return (
    <TranslationContext.Provider value={{
      STRINGS: file,
      setToEnglish, setToSpanish
    }}>
      {children}
    </TranslationContext.Provider>
  )
}
