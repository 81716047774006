import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import LoginPage from "../pages/LoginPage";
import config from "../assets/config.json";
import NavBar from "./NavBar";
import { useUserContext } from "../context/UserContext";
import RegisterPage from "../pages/RegisterPage";
import SetUpPage from "../pages/SetUpPage";
import HomePage from "../pages/HomePage";
import ConfigurationPage from "../pages/ConfigurationPage";
import { getNestedValue } from "../common/functions";
import BillingPage from "../pages/BillingPage";
import InvitePage from "../pages/InvitePage";
import PreferencesPage from "../pages/PreferencesPage";
import ZenDeskWidget from "../common/ZenDeskWidget";
import ForgotPasswordPage from "../pages/ForgotPasswordPage";
import NotFoundPage from "../pages/NotFoundPage";
import FulfillmentPolicy from "../pages/FulfillmentPolicy";

function RequireAuth({ children }) {
  const { user } = useUserContext();

  if (!user) {
    return <Navigate to={"/login"} />;
  }

  return children;
}

function NonAuthRoute({ children }) {
  const { user } = useUserContext();

  if (user) {
    return <Navigate to={"/home"} />;
  }

  return children;
}

function RequiresSetUp({ children }) {
  const { user } = useUserContext();

  if (
    !user[config.setupOptions.mainKey] ||
    !user[config.setupOptions.mainKey][config.setupOptions.setupKey]
  ) {
    return <Navigate to={"/setup"} />;
  }

  return children;
}

function RequiresPayment({ children }) {
  const { user } = useUserContext();

  const goodStanding = getNestedValue(user, config.goodStandingKey);
  console.log("goodStanding: ", goodStanding);

  if (!goodStanding) {
    return <Navigate to={"/billing"} />;
  }

  return children;
}

function AppRouter() {
  const { user, loading } = useUserContext();

  if (loading) {
    return null;
  }

  return (
    <>
      {user && <ZenDeskWidget />}
      <Router>
        {user && (
          <Routes>
            <Route path={"/*"} element={<NavBar />} />
          </Routes>
        )}
        <Routes>
          <Route path={"/"} exact element={<Navigate to={"/login"} />} />
          {config.allowLogin && (
            <Route
              path={"/login"}
              exact
              element={
                <NonAuthRoute>
                  <LoginPage />
                </NonAuthRoute>
              }
            />
          )}
          {config.allowPasswordReset && (
            <Route
              path={"/forgot-password"}
              exact
              element={
                <NonAuthRoute>
                  <ForgotPasswordPage />
                </NonAuthRoute>
              }
            />
          )}
          {config.allowRegister && (
            <Route
              path={"/register"}
              exact
              element={
                <NonAuthRoute>
                  <RegisterPage />
                </NonAuthRoute>
              }
            />
          )}

          <Route
            path={"/setup"}
            exact
            element={
              <RequireAuth>
                {user &&
                user[config.setupOptions.mainKey] &&
                user[config.setupOptions.mainKey][
                  config.setupOptions.setupKey
                ] ? (
                  <Navigate to={"/home"} />
                ) : (
                  <SetUpPage />
                )}
              </RequireAuth>
            }
          />

          <Route
            path="/fullfillment-policy"
            exact
            element={<FulfillmentPolicy />}
          />

          <Route
            path={"/configuration"}
            exact
            element={
              <RequireAuth>
                <RequiresSetUp>
                  <RequiresPayment>
                    <ConfigurationPage />
                  </RequiresPayment>
                </RequiresSetUp>
              </RequireAuth>
            }
          />
          <Route
            path={"/preferences"}
            exact
            element={
              <RequireAuth>
                <RequiresSetUp>
                  <PreferencesPage />
                </RequiresSetUp>
              </RequireAuth>
            }
          />
          <Route
            path={"/referral"}
            exact
            element={
              <RequireAuth>
                <RequiresSetUp>
                  <InvitePage />
                </RequiresSetUp>
              </RequireAuth>
            }
          />

          <Route
            path={"/billing"}
            exact
            element={
              <RequireAuth>
                <RequiresSetUp>
                  <BillingPage />
                </RequiresSetUp>
              </RequireAuth>
            }
          />

          <Route
            path={"/home"}
            exact
            element={
              <RequireAuth>
                <RequiresSetUp>
                  <RequiresPayment>
                    <HomePage />
                  </RequiresPayment>
                </RequiresSetUp>
              </RequireAuth>
            }
          />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
    </>
  );
}

export default AppRouter;
