import { Flex, Text } from "@chakra-ui/react";
import config from "../assets/config.json";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function FulfillmentPolicy() {
  const navigate = useNavigate();
  return (
    <>
      <Flex
        boxShadow={"sm"}
        alignItems={"center"}
        bg={"white"}
        pl={8}
        pt={4}
        pb={4}
        pr={8}
      >
        <Text
          cursor={"pointer"}
          onClick={() => navigate("/home")}
          color={"black"}
          fontSize={"lg"}
          fontWeight={"bold"}
        >
          {config.appName}{" "}
          <Text as={"span"} fontSize={"xs"}>
            v{config.appVersion}
          </Text>
        </Text>
      </Flex>
      <Flex flex direction="column" p={4} m={8} bg="white" boxShadow="sm">
        <Text fontSize="lg" fontWeight="bold">
          Return and Cancellation Policy
        </Text>
        <Text mt={4}>
          If you are dissatisfied with your service withing 48 of purchasing you
          can request a refund and cancel your service which will be at the
          discression of you customer service associate.
        </Text>
      </Flex>
    </>
  );
}
