import {useState} from "react";

export function useToggle(defaultState = false) {
  const [open, setOpen] = useState(defaultState);

  function toggle() {
    setOpen((state) => !state);
  }

  return { open, toggle } 
}


export function useFormInputs(keys) {
  const [form, setForm] = useState(generateBaseForm());

  function generateBaseForm() {
    const res = {};
    for (const key of keys) {
      res[key] = "";
    }

    return res;
  }

  function onChange({ target, }) {
    const { name, value } = target;
    const newForm = JSON.parse(JSON.stringify(form));
    newForm[name] = value;
    setForm(newForm);
  }

  return { form, onChange };
}

export function useSteps(initialStep, numberOfSteps) {
  const [step, setStep] = useState(initialStep);

  function isLastStep() {
    return step === numberOfSteps - 1;
  }

  function nextStep() {
    if (step + 1 > numberOfSteps) {
      setStep(0);
    } else {
      setStep(step + 1);
    }
  }

  function getCurrentStep() {
    return step
  }

  function goBack() {
    setStep(step - 1);
  }

  return {
    nextStep, getCurrentStep,
    goBack,
     isLastStep
  }
}

export function useFormInput(defaultInputValue = "", override) {
  const [value, setValue] = useState(defaultInputValue);

  function onChange({ target }) {
    if (override) {
      setValue(override(target.value));
    } else {
      setValue(target.value);
    }
  }

  return {
    value, onChange
  }
}
export function useLoader(defaultLoadingState) {
  const [loading, setLoading] = useState(defaultLoadingState)


  function start() {
    setLoading(true);
  }

  function stop() {
    setLoading(false);
  }

  function isLoading() {
    return loading;
  }

  return {
    isLoading,
    start,
    stop
  }
}
