import ConfigOptionWrapper from "./ConfigOptionWrapper";
import {Select} from "@chakra-ui/react";
import {useUserContext} from "../context/UserContext";
import config from '../assets/config.json';
import moment from "moment";
import {useTranslationContext} from "../context/TranslationContext";

function EndTime({ onChange }) {
  const { user } = useUserContext();
  const { STRINGS } = useTranslationContext();

  function getEndTimeOptions() {
    const { startHour } = user[config.setupOptions.mainKey]
    const endTime = 23 - startHour;
    return new Array(endTime).fill(startHour).map((item, idx) => item + idx + 1)
  }
  return (
    <ConfigOptionWrapper
      label={STRINGS.endTime}
      helperText={STRINGS.selectHourStop}
    >
      <Select name={"hours"} onChange={(e) => onChange(e, 1)} value={user[config.setupOptions.mainKey]?.hours || 1}>
        {getEndTimeOptions().map(time => (
          <option value={time}>{moment().set('hour', time).set('minute', 0).format("h A")}</option>
        ))}

      </Select>

    </ConfigOptionWrapper>
  )
}

export default EndTime;
