import {Button, Flex, FormControl, FormHelperText, FormLabel, Input, Text, useToast} from "@chakra-ui/react";
import PageWrapper from "../components/PageWrapper";
import {useState} from "react";
import {useUserContext} from "../context/UserContext";
import {useFormInput, useLoader} from "../utils/hooks";
import {cleanPhoneNumber, validatePhoneNumber} from "../common/functions";
import {patchUser} from "../services/UserService";
import {useTranslationContext} from "../context/TranslationContext";

function PreferencesPage() {
  const { user, load } = useUserContext();
  const preferredNumber = useFormInput(user.preferredNumber || user.phoneNumber)
  const toast = useToast();
  const { STRINGS } = useTranslationContext();
  const loader = useLoader();

  function isUpdateButtonDisabled() {
    const invalid = validatePhoneNumber(preferredNumber.value);
    if (!invalid) return true;

    return false;
  }

  async function onUpdateClick() {
    try {
      loader.start();
      await patchUser({
        userId: user.userId,
        body: {
          preferredNumber: cleanPhoneNumber(preferredNumber.value),
        }
      });

      await load();

      toast({
        status: "success",
        title: "Preferences",
        description: "Successfully updated your preferred number"
      });
      loader.stop();
    } catch(e) {
      loader.stop();
      toast({
        status: "error",
        title: "Preferences",
        description: "Unable to update your preferred number"
      });
    }
  }

  return (
    <PageWrapper padding={"32px 32px 48px 32px"}>
      <Text fontSize={"2xl"} fontWeight={"medium"}>
        {STRINGS.preferences}
      </Text>
      <Text>
        {STRINGS.changeUserPreferences}
      </Text>
      <Flex mt={8} position={"relative"} boxShadow={"sm"} bg={"white"} borderRadius={8} pl={4} pt={6} pb={8} pr={4}
            flexDirection={"column"}>
        <Text fontSize={"2xl"} fontWeight={"semibold"}>
          {STRINGS.contactInfo}
        </Text>
        <FormControl mt={4}>
          <FormLabel>
            {STRINGS.contactMeAt}
          </FormLabel>
          <Input  {...preferredNumber}/>
          <FormHelperText>
            {STRINGS.thisIsWhereWeNotifyYou}
          </FormHelperText>
        </FormControl>
        <Button isLoading={loader.isLoading()} onClick={onUpdateClick} isDisabled={isUpdateButtonDisabled()} alignSelf={"flex-start"} size={"sm"} mt={6}>
          {STRINGS.update}
        </Button>
      </Flex>
    </PageWrapper>
  )
}

export default PreferencesPage
