import {Link, useHistory, useNavigate} from "react-router-dom";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  PinInput,
  PinInputField,
  Spinner,
  Stack,
  Text,
  useToast
} from "@chakra-ui/react";
import PageWrapper from "../components/PageWrapper";
import {useFormInput, useLoader} from "../utils/hooks";

import config from '../assets/config.json';
import { useSearchParams } from 'react-router-dom'
import {useEffect, useState} from "react";
import {getInviteById} from "../services/InviteService";
import { Auth } from 'aws-amplify';
import {cleanPhoneNumber, validatePhoneNumber} from "../common/functions";
import CodeVerification from "../components/CodeVerification";
import {useUserContext} from "../context/UserContext";
import {useTranslationContext} from "../context/TranslationContext";
import {getReferralCodes} from "../services/UserService";

function RegisterPage() {
  const name = useFormInput();
  const phoneNumber = useFormInput("", (val) => {
    val = val.replace(/ /gm, '');
    let num = `${val.substring(0, 3)} ${val.substring(3, 6)} ${val.substring(6, val.length)}`;

    return num.trim();
  });
  const password = useFormInput();
  const confirmPassword = useFormInput();

  const { load } = useUserContext();
  const loader = useLoader(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const inviteId = searchParams.get("inviteId");
  const referralCode = searchParams.get("referralCode");
  const [loadingInvite, setLoadingInvite] = useState(inviteId);
  const toast = useToast();
  const navigate = useNavigate();
  const [invite, setInvite] = useState(null);
  const [showConfirmationView, setShowConfirmationView] = useState(false);
  const [code, setCode] = useState("");
  const { STRINGS } = useTranslationContext();

  async function onSignUp() {
    try {
      loader.start();

      let code = "MASACRE";


      if (referralCode) {
        try {
          const codes = await getReferralCodes();
          const found = codes.find(item => item.code === referralCode)
          if (found) {
            code = found.code;
          } else {
            toast({
              title: "Register",
              description: "The referral code that is being used is incorrect",
              status: "error"
            });
            loader.stop();
            return;
          }

        } catch(e) {
          toast({
            title: "Register",
            description: "The referral code that is being used is incorrect",
            status: "error"
          });
          loader.stop();
          return;
        }
      }


      const { user } = await Auth.signUp({
        username: invite ? invite.invited : cleanPhoneNumber(phoneNumber.value),
        password: password.value,
        attributes: {
          given_name: name.value,          // optional
          "custom:brand": config.brand,
          "custom:referralCode": invite ? invite.referralCode : code
        }
      });

      await Auth.signIn(cleanPhoneNumber(phoneNumber.value), password.value);
      await load();
      console.log(user);
    } catch (error) {
      loader.stop();
      toast({
        status: "error",
        title: "Register",
        description: error.message
      });
    }
  }

  async function loadInvite() {
    try {
      const response = await getInviteById(inviteId);

      if (!response) {
        return;
      }

      if (response.status?.includes("REGISTERED")) {
        toast({
          status: "success",
          title: "Invitation",
          description: STRINGS.youAlreadyRegistered
        });

        navigate('/login');

      } else {
        setTimeout(() => {
          setLoadingInvite(false);
          setInvite(response);
        }, 1000)
      }

    } catch(e) {
      console.log("error getting invite: ", e);
    }
  }

  useEffect(() => {
    if (inviteId) {
      loadInvite();
    }
  }, []);


  if (loadingInvite) {
    return (
      <PageWrapper>
        <Link to={"/"}>
          <Text cursor={"pointer"} mt={4} fontSize={"3xl"} fontWeight={"bold"}
                textAlign={"center"}>{config.appName}</Text>
        </Link>
        <Flex boxShadow={"lg"} mt={6} maxW={400} width={"100%"} alignSelf={"center"} p={8} borderRadius={8} bg={"white"}
              pt={12} pb={12} flexDirection={"column"}>
          <Text textAlign={"center"} fontSize={"xl"} fontWeight={"semibold"}>Welcome</Text>
          <Text textAlign={"center"} mt={4} fontSize={"lg"}>
            {STRINGS.youHaveBeenInvitedTo}{config.appName}!
          </Text>
          <Spinner mt={4} size={"lg"} alignSelf={"center"}/>
          <Text textAlign={"center"} mt={4}>
            {STRINGS.loadingYourInvitation}
          </Text>
        </Flex>
      </PageWrapper>
    )
  }



  return (
    <PageWrapper>
      <Link to={"/"}>
        <Text cursor={"pointer"} mt={4} fontSize={"3xl"} fontWeight={"bold"}
              textAlign={"center"}>{config.appName}</Text>
      </Link>
      <Flex boxShadow={"lg"} mt={6} maxW={400} width={"100%"} alignSelf={"center"} p={8} borderRadius={8} bg={"white"}
            pt={12} pb={12} flexDirection={"column"}>
        {showConfirmationView && (
          <CodeVerification phoneNumber={phoneNumber.value} />
        )}
        {!showConfirmationView && (
          <>
            <Text textAlign={"center"} fontSize={"xl"} fontWeight={"semibold"}>Create your account</Text>
            <Stack spacing={4} mt={8}>
              <FormControl>
                <FormLabel>{STRINGS.name}</FormLabel>
                <Input {...name} placeholder={"John Doe"}/>
              </FormControl>
              <FormControl>
                <FormLabel>{STRINGS.phoneNumber}</FormLabel>
                <InputGroup>
                <InputLeftElement>
                <Text>
                  +1
                </Text>
                </InputLeftElement>
                <Input {...phoneNumber} value={invite ? invite.invited : phoneNumber.value} disabled={invite} placeholder={"555 555 5555"}/>
                </InputGroup>
              </FormControl>
              <FormControl>
                <FormLabel>{STRINGS.password}</FormLabel>
                <Input {...password} type={"password"} placeholder={"******"}/>
              </FormControl>
              <FormControl>
                <FormLabel>{STRINGS.confirmPassword}</FormLabel>
                <Input {...confirmPassword} type={"password"} placeholder={"******"}/>
              </FormControl>
              <Button disabled={!validatePhoneNumber(phoneNumber.value)|| !name.value || !password.value || !confirmPassword.value || !phoneNumber.value || password.value !== confirmPassword.value} isLoading={loader.isLoading()}  onClick={onSignUp}>
                {STRINGS.signUp}
              </Button>
              <Text fontSize={"sm"} textAlign={"center"}>
                {STRINGS.alreadyHaveAnAccount} <Link to={"/login"}><Text cursor={"pointer"} textDecoration={"underline"}
                                                                         as={"span"}>{STRINGS.login}</Text></Link>
              </Text>
            </Stack>

          </>
        )}
      </Flex>
    </PageWrapper>
  )
}


export default RegisterPage;
