import {Button, Stack, Text, useToast} from "@chakra-ui/react";
import config from '../assets/config.json';
import MinBlockHours from "./MinBlockHours";
import {useLoader, useSteps, useToggle} from "../utils/hooks";
import StartTime from "./StartTime";
import EndTime from "./EndTime";
import TimeBuffer from "./TimeBuffer";
import Locations from "./Locations";
import {useUserContext} from "../context/UserContext";
import {patchUser} from "../services/UserService";
import {useTranslationContext} from "../context/TranslationContext";
import DistanceToLocation from "./DistanceToLocation";
import MinPrice from "./MinPrice";
import AdvancedConfigurationToggle from "./AdvancedConfigurationToggle";
import DeliveryLocation from "./DeliveryLocation";

function Configuration({
                         showSteps = true
                       }) {
  const steps = useSteps(0, config.configurationOptions.length);
  const {user, load} = useUserContext();
  const toast = useToast({
    position: "bottom-left"
  })
  const loader = useLoader();
  const { STRINGS } = useTranslationContext();

  const advancedOptionsToggle = useToggle();
  async function onDeliveryLocationChange(newLocation) {
    console.log("here")
    let locations = [];

    if (user[config.setupOptions.mainKey] && user[config.setupOptions.mainKey].deliveryLocations) {
      locations = user[config.setupOptions.mainKey].deliveryLocations;
    }



    let idxOf = locations.indexOf(newLocation);

    if (idxOf > -1) {
      locations.splice(idxOf, 1);
    } else {
      locations.push(newLocation);
    }


    let options = {
      deliveryLocations: locations
    };

    if (user[config.setupOptions.mainKey]) {
      options = {
        ...user[config.setupOptions.mainKey],
        ...options
      }
    }

    try {
      loader.start();
      await patchUser({
        userId: user.userId,
        body: {
          [config.setupOptions.mainKey]: options
        }
      });

      await load();

      if (!showSteps) {
        toast({
          title: "Configuration",
          status: "success",
          description: STRINGS.successfullyUpdatedConfig
        })
      }


      loader.stop();

    } catch (e) {
      console.log("E: ", e);
      toast({
        status: "error",
        title: "Configuration Error",
        description: STRINGS.unableUpdateConfig
      })

      loader.stop();
    }
  }

  async function onLocationChange(newLocation) {
    let locations = [];

    if (user[config.setupOptions.mainKey] && user[config.setupOptions.mainKey].locations) {
      locations = user[config.setupOptions.mainKey].locations;
    }


    let idxOf = locations.indexOf(newLocation);

    if (idxOf > -1) {
      locations.splice(idxOf, 1);
    } else {
      locations.push(newLocation);
    }


    let options = {
      locations: locations
    };

    if (user[config.setupOptions.mainKey]) {
      options = {
        ...user[config.setupOptions.mainKey],
        ...options
      }
    }

    try {
      loader.start();
      await patchUser({
        userId: user.userId,
        body: {
          [config.setupOptions.mainKey]: options
        }
      });

      await load();

      if (!showSteps) {
        toast({
          title: "Configuration",
          status: "success",
          description: STRINGS.successfullyUpdatedConfig
        })
      }


      loader.stop();

    } catch (e) {
      console.log("E: ", e);
      toast({
        status: "error",
        title: "Configuration Error",
        description: STRINGS.unableUpdateConfig
      })

      loader.stop();
    }
  }

  async function onFinalize() {
    let options = {
      [config.setupOptions.setupKey]: true
    }

    if (user[config.setupOptions.mainKey]) {
      options = {
        ...user[config.setupOptions.mainKey],
        ...options
      }
    }

    loader.start();

    try {
      await patchUser({
        userId: user.userId,
        body: {
          [config.setupOptions.mainKey]: options,
        }
      })

      await load();
      loader.stop();

    } catch (e) {
      toast({
        status: "error",
        title: "Configuration Error",
        description: STRINGS.notAbleFinalizeConfig
      })
      loader.stop();
    }

  }


  async function onConfigItemChange({target}, sampleValue) {
    const {value, name} = target;

    let options = {
      [name]: typeof sampleValue === "number" ? Number(value) : String(value)
    };

    if (user[config.setupOptions.mainKey]) {
      options = {
        ...user[config.setupOptions.mainKey],
        ...options
      }
    }


    try {
      loader.start();
      await patchUser({
        userId: user.userId,
        body: {
          [config.setupOptions.mainKey]: options
        }
      });

      await load();

      if (!showSteps) {
        toast({
          title: "Configuration",
          status: "success",
          description: STRINGS.successfullyUpdatedConfig
        })
      }

      loader.stop();

    } catch (e) {
      console.log("E: ", e);
      toast({
        status: "error",
        title: "Configuration Error",
        description: STRINGS.unableUpdateConfig
      })

      loader.stop();
    }

  }


  return (
    <>
      <Stack alignSelf={"center"} width={"100%"} spacing={4} mt={8}>
        {showSteps && (
          <>
            {config.configurationOptions[steps.getCurrentStep()] === "minPrice" && (
              <MinPrice onChange={onConfigItemChange}/>
            )}
            {config.configurationOptions[steps.getCurrentStep()] === "minBlockHours" && (
              <MinBlockHours onChange={onConfigItemChange}/>
            )}
            {config.configurationOptions[steps.getCurrentStep()] === "startHour" && (
              <StartTime onChange={onConfigItemChange}/>
            )}
            {config.configurationOptions[steps.getCurrentStep()] === "hours" && (
              <EndTime onChange={onConfigItemChange}/>
            )}
            {config.configurationOptions[steps.getCurrentStep()] === "timeBuffer" && (
              <TimeBuffer onChange={onConfigItemChange}/>
            )}
            {config.configurationOptions[steps.getCurrentStep()] === "locations" && (
              <Locations onChange={onLocationChange}/>
            )}
                {config.configurationOptions[steps.getCurrentStep()] === "distanceToLocation" && (
                  <DistanceToLocation onChange={onLocationChange} onDistanceChange={onConfigItemChange} />
                )}
          </>
        )}
        {!showSteps && (
          <>
            {config.configurationOptions.map(option => (
              <>
                {option=== "minPrice" && (
                  <MinPrice onChange={onConfigItemChange}/>
                )}
                {option=== "minBlockHours" && (
                  <MinBlockHours onChange={onConfigItemChange}/>
                )}
                {option === "startHour" && (
                  <StartTime onChange={onConfigItemChange}/>
                )}
                {option === "hours" && (
                  <EndTime onChange={onConfigItemChange}/>
                )}
                {option === "timeBuffer" && (
                  <TimeBuffer onChange={onConfigItemChange}/>
                )}
                {option === "locations" && (
                  <Locations onChange={onLocationChange}/>
                )}
                {option === "distanceToLocation" && (
                  <DistanceToLocation onChange={onLocationChange} onDistanceChange={onConfigItemChange} />
                )}
              </>
            ))}
            <AdvancedConfigurationToggle show={advancedOptionsToggle.open} toggle={advancedOptionsToggle.toggle} />
            {advancedOptionsToggle.open && config.advancedConfigurationOptions.map(option => (
              <>
                {option === "deliveryLocation" && (
                  <DeliveryLocation onChange={onDeliveryLocationChange} />
                )}
              </>
            ))}
          </>
        )}
        {showSteps && (
          <Stack direction={"row"} alignSelf={"flex-end"} spacing={4}>
            {steps.getCurrentStep() > 0 && (
              <Button variant={"outline"} onClick={steps.goBack}>
                {STRINGS.back}
              </Button>
            )}
            <Button isLoading={loader.isLoading()} isDisabled={loader.isLoading()}
                    onClick={steps.isLastStep() ? onFinalize : steps.nextStep}>
              {STRINGS.continue}
            </Button>
          </Stack>
        )}
      </Stack>
    </>
  )
}

export default Configuration;
