import {useUserContext} from "../context/UserContext";
import {useEffect, useMemo} from "react";
import Zendesk from "react-zendesk";
import  { ZendeskAPI } from 'react-zendesk';
import {useLoader} from "../utils/hooks";

const setting = {
  color: {
    theme: "#000"
  },
  launcher: {
    chatLabel: {
      "en-US": "Necesitas ayuda"
    }
  },
  contactForm: {
    fields: [
      { id: "description", prefill: { "*": "My pre-filled description" } }
    ]
  }
};

function ZenDeskWidget() {
  const { user } = useUserContext();
  const loader = useLoader(true);

  async function loadData() {
    ZendeskAPI('webWidget', 'prefill', {
      name: user.name,
      phoneNumber: user.phoneNumber
    });
    const r = ZendeskAPI('webWidget', 'identify', {
      name: user.name  + " " + user.phoneNumber + " " + user.referralCode,
      phone_number: user.phoneNumber
    });

  }



  return (
    <Zendesk defer zendeskKey={"cd1b1c6f-b298-479c-a49f-19a353745e09"} {...setting} onLoaded={loadData} />
  )
}


export default ZenDeskWidget
