import React from 'react';
import {Text, Flex, Stack, FormControl, FormLabel, Input, Button, useToast} from '@chakra-ui/react';
import config from '../assets/config.json';
import {INPUT_PLACEHOLDERS} from "../common/convertions";
import {useFormInputs, useLoader} from "../utils/hooks";
import {validateCredentials} from "../services/SetUpService";
import {patchUser} from "../services/UserService";
import {useUserContext} from "../context/UserContext";
import {useTranslationContext} from "../context/TranslationContext";


function Credentials({ onContinue }) {
  const formInputs = useFormInputs(config.requiredCredentials);
  const loader = useLoader(false);
  const { user, load } = useUserContext();
  const toast = useToast();
  const { STRINGS } = useTranslationContext();


  async function onValidateClick() {
    loader.start();
    try {
      const response = await validateCredentials(formInputs.form);

      let options = {
        ...formInputs.form,
      };

      if (config.setCredentialsResponseToUser) {
        for (const key of config.setCredentialsResponseToUser) {
        options[key] = response[key];
        }
      }

      if (user[config.setupOptions.mainKey]) {
        options = {
          ...user[config.setupOptions.mainKey],
          ...options
        }
      }

      for (const key in config.defaultConfig) {
        if (options.hasOwnProperty(key)) continue;
        options[key] = config.defaultConfig[key];
      }

      const body = {
        [config.setupOptions.mainKey]: options
      }

      await patchUser({
        userId: user.userId,
        body
      })


      await load();

      loader.stop()
      onContinue();
    } catch(e) {
      toast({
        description: STRINGS.incorrectUsernameOrPassword,
        status: "error",
        title: "Validation Error"
      });
      loader.stop()
    }
  }

  return (
    <Flex width={"100%"} maxW={450}  boxShadow={"md"} borderRadius={8} p={8} bg={"white"} flexDirection={"column"} mt={8} alignSelf={"center"}>
      <Text fontSize={"lg"} textAlign={"center"}>
        {STRINGS.enterAccountCredentials.replace("account", config.service)}
      </Text>
      <Stack spacing={4} mt={8}>
        {config.requiredCredentials.map(credential => (
          <FormControl>
            <FormLabel  textTransform={"capitalize"}>
              {credential}
            </FormLabel>
            <Input name={credential} value={formInputs.form[credential]} onChange={formInputs.onChange} placeholder={INPUT_PLACEHOLDERS[credential]} type={credential === "password" && "password"} />
          </FormControl>
        ))}
        <Button isDisabled={loader.isLoading()} isLoading={loader.isLoading()} onClick={onValidateClick}>
          {STRINGS.continue}
        </Button>

      </Stack>
    </Flex>
  )
}

export default Credentials;
