import {
  Box,
  Button,
  Flex,
  Th,
  Td,
  Tr,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Table,
  Tbody,
  Text,
  Thead,
  useToast
} from "@chakra-ui/react";
import PageWrapper from "../components/PageWrapper";
import {GiPresent} from "react-icons/gi";
import {useUserContext} from "../context/UserContext";
import {useFormInput, useLoader} from "../utils/hooks";
import {getInvites, sendInvite} from "../services/InviteService";
import { useState, useEffect} from "react";
import {cleanPhoneNumber, validatePhoneNumber} from "../common/functions";
import config from "../assets/config.json";
import {useTranslationContext} from "../context/TranslationContext";


function InvitePage() {
  const { STRINGS } = useTranslationContext();
  const { user } = useUserContext();
  const loader = useLoader(true);
  const [invites, setInvites] = useState([]);
  const phoneNumber = useFormInput("", (val) => {
    val = val.replace(/ /gm, '');
    let num = `${val.substring(0, 3)} ${val.substring(3, 6)} ${val.substring(6, val.length)}`;

    return num.trim();
  });
  const toast = useToast()

  async function loadData() {
    try {
      const response = await getInvites(user.userId);
      setInvites(response);
      loader.stop();
    } catch(e) {
      loader.stop();
      toast({
        status: "error",
        title: "Invite Error",
        description: STRINGS.errorLoadingInvites
      });
    }
  }

  useEffect(() => {
    loadData();
  }, []);


  async function onSendClick() {
    try {
      loader.start();
      await sendInvite(cleanPhoneNumber(phoneNumber.value));
      await loadData();
      loader.stop();
      toast({
        status: "success",
        title: "Invite",
        description: STRINGS.inviteSentSuccessfully
      })
    } catch(e) {
      loader.stop();
      toast({
        status: "error",
        description: e?.response?.data || STRINGS.errorSendingInvite,
        title: "Invite Error"
      })

    }
  }
  return (
    <PageWrapper padding={"32px 32px 48px 32px"}>
      <Text fontSize={"2xl"} fontWeight={"medium"}>
        {STRINGS.referAFriend}<GiPresent style={{display: "inline"}}/>
      </Text>
      <Text whiteSpace={"break-spaces"}>
        {STRINGS.startYourWeekWithAFriend}
        {"\n"}
        {STRINGS.ifTheyPay}<Text as={"span"} fontWeight={"bold"}>{STRINGS.you}</Text>{STRINGS.getAWeekFree}
        {"\n"}
        {STRINGS.itIsThatSimple}
      </Text>
      <Flex mt={8} position={"relative"} boxShadow={"sm"} bg={"white"} borderRadius={8} pl={4} pt={6} pb={8} pr={4}
            flexDirection={"column"}>
        <Text fontSize={"2xl"} fontWeight={"semibold"}>
          {STRINGS.sendInvite}
        </Text>
        <FormControl mt={4}>
          <FormLabel>
            {STRINGS.phoneNumber}
          </FormLabel>
          <Input {...phoneNumber} placeholder={STRINGS.yourFriendPhoneNumber + " e.g. 302 555 5555"}/>
        </FormControl>
        <Text fontSize={"lg"} mt={4}>
          {STRINGS.previewMessage}
        </Text>
        <Box borderRadius={8} p={2} bg={"gray.50"}>
          <Text whiteSpace={"break-spaces"}>
            {STRINGS.weAreExcitedMessage.replace("APP_NAME", config.appName).replace("USERNAME", user.name)}
          </Text>
        </Box>
        <Button isLoading={loader.isLoading()} disabled={!phoneNumber.value || phoneNumber.value.length <10 || !validatePhoneNumber(phoneNumber.value)} onClick={onSendClick} mt={4} size={"sm"}>
          {STRINGS.send}
        </Button>
      </Flex>
      <Flex mt={8} position={"relative"} boxShadow={"sm"} bg={"white"} borderRadius={8} pl={4} pt={6} pb={8} pr={4}
            flexDirection={"column"}>
        <Text fontSize={"2xl"} fontWeight={"semibold"}>
          {STRINGS.invitesYouSent}
        </Text>
        <Table size={"sm"} mt={4}>
          <Thead>
            <Tr>
              <Th>
                {STRINGS.youInvited}
              </Th>
              <Th>
                {STRINGS.haveTheyPaid}
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {invites.map(invite => (
              <Tr>
                <Td>
                  {invite.invited}
                </Td>
                <Td>
                {invite.status.includes("PAID") ? "yes" : "no"}
                </Td>
              </Tr>
              ))}
          </Tbody>
        </Table>
      </Flex>
    </PageWrapper>
  )
}

export default InvitePage;
