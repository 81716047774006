export function timeSince(timeStamp) {
  var now = new Date(),
    secondsPast = (now.getTime() - timeStamp) / 1000;
  if (secondsPast < 60) {
    return parseInt(secondsPast) + 's';
  }
  if (secondsPast < 3600) {
    return parseInt(secondsPast / 60) + 'm';
  }
  if (secondsPast <= 86400) {
    return parseInt(secondsPast / 3600) + 'h';
  }
  if (secondsPast > 86400) {
    var day = timeStamp.getDate();
    var month = timeStamp.toDateString().match(/ [a-zA-Z]*/)[0].replace(" ", "");
    var year = timeStamp.getFullYear() == now.getFullYear() ? "" : " " + timeStamp.getFullYear();
    return day + " " + month + year;
  }
}


export function getNestedValue(obj, key) {
  const split = key.split(".");

  if (split.length > 1) {
    const [left, right] = split;
    if (obj[left]) return obj[left][right];
    return null;
  }
  return obj[key];
}

export function validatePhoneNumber(input_str) {
  var re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

  return re.test(input_str);
}

export function cleanPhoneNumber(phoneNumber) {
  let realPhoneNumber;

  realPhoneNumber = phoneNumber
    .replace(/\(/g, '')
    .replace(/\)/g, '')
    .replace(/-/g, '')
    .replace(/ /g, '')
    .trim();

  if (!realPhoneNumber.includes('+1')) {
    if (realPhoneNumber.includes('+')) {
      realPhoneNumber = realPhoneNumber.replace(/\+/g, '+1');
    } else {
      realPhoneNumber = '+1' + realPhoneNumber;
    }
  }

  return realPhoneNumber;
}
