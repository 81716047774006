import axios from 'axios';
import { Auth } from "aws-amplify";

const instance = axios.create({
  baseURL: "https://hocx8gfype.execute-api.us-east-1.amazonaws.com/prd",
})

instance.interceptors.request.use(async function (config)  {

  try {
    const session = await Auth.currentSession();

    if (session && session.isValid()) {
      config.headers["Authorization"] = session.getIdToken().getJwtToken();
    }
  } catch(e) {}


  return config;
})


export default instance;
