import React, { useState } from "react";
import { useTranslationContext } from "../context/TranslationContext";
import ConfigOptionWrapper from "./ConfigOptionWrapper";
import {
  Button,
  Checkbox,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useUserContext } from "../context/UserContext";
import facilities from "../assets/facilities.json";
import config from "../assets/config.json";

export default function DistanceToLocation({ onChange, onDistanceChange }) {
  const { STRINGS } = useTranslationContext();
  const { user } = useUserContext();

  return (
    <ConfigOptionWrapper
      label={STRINGS.distanceToLocation}
      helperText={STRINGS.distanceToLocationHelperText}
    >
      <TableContainer
        borderRadius={4}
        p={2}
        border={"1px solid lightgray"}
        borderColor={"gray.300"}
        overflowY={"scroll"}
        maxHeight={300}
      >
        <Table size={"small"}>
          <Thead>
            <Tr>
              <Th>Active{"__"}</Th>
              <Th>Name</Th>
            </Tr>
          </Thead>
          <Tbody>
            {facilities.data.getFacilities
              .filter(
                (item) =>
                  user[config.setupOptions.mainKey].facilityIds?.includes(
                    item.facilityId
                  ) ||
                  user[config.setupOptions.mainKey].facilityIds?.includes(
                    item.legacyId
                  )
              )
              .map((location) => {
                return (
                  <Tr>
                    <Td pl={2} pr={2}>
                      <Checkbox
                        onChange={() => onChange(location.facilityId)}
                        isChecked={user[
                          config.setupOptions.mainKey
                        ]?.locations?.includes(location.facilityId)}
                      />
                    </Td>
                    <Td>
                      <Text>
                        {location.shortCode} - {location.name}
                      </Text>
                      <Text>{location.address.street}</Text>
                    </Td>
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </TableContainer>
    </ConfigOptionWrapper>
  );
}
