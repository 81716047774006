import ConfigOptionWrapper from "./ConfigOptionWrapper";
import { Button, CloseButton, Flex, Input, List, ListItem, Select, Text, useToast } from "@chakra-ui/react";
import config from '../assets/config.json';
import { useUserContext } from "../context/UserContext";
import { useTranslationContext } from "../context/TranslationContext";
import { useFormInput } from "../utils/hooks";


function DeliveryLocation({
  onChange
}) {
  const { user } = useUserContext();
  const { STRINGS } = useTranslationContext();
  const toast = useToast({
    position: "bottom-left"
  })
  const currentString = useFormInput("")

  return (
    <ConfigOptionWrapper label={STRINGS.deliveryLocation} helperText={STRINGS.enterDeliveryLocations}>
      <Flex gap={4}>
        <Input {...currentString} placeholder="Enter delivery location here ex. Houston" />
        <Button onClick={() => {
          let locations = [];

          if (user[config.setupOptions.mainKey] && user[config.setupOptions.mainKey].deliveryLocations) {
            locations = user[config.setupOptions.mainKey].deliveryLocations;
          }
          if (locations.includes(currentString.value.trim())) {
            toast({
              title: STRINGS.deliveryLocation,
              status: "error",
              description: STRINGS.locationAlreadyExists 
            })
            return
          }

          currentString.onChange({ target: { value: "" } })
          onChange(currentString.value.trim())
        }}>
          Add
        </Button>
      </Flex>

      <List pt={4}>
        {user?.vehoOptions?.deliveryLocations?.map(item => (
          <ListItem flex={"row"} display={"flex"} alignItems={"center"} justifyContent={"space-between"} padding={2} borderBottomWidth={2}>

            <Text fontWeight={"semibold"}>
              {item}
            </Text>
            <CloseButton onClick={() => onChange(item)} color={"red.400"} />

          </ListItem>
        ))}
      </List>
    </ConfigOptionWrapper>
  )
}

export default DeliveryLocation;
