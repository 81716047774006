import {ChakraProvider } from "@chakra-ui/react";
import AppRouter from "./components/AppRouter";
import UserContextProvider from "./context/UserContext";
import theme from "./utils/theme";
import config from './assets/config.json';
import {configureAmplify} from "./utils/amplifyConfig";
import TranslationContextProvider from "./context/TranslationContext";


document.title = config.appName;
configureAmplify();



function App() {


  return (
    <ChakraProvider theme={theme}>
        <UserContextProvider>
          <TranslationContextProvider>
            <AppRouter />
          </TranslationContextProvider>
        </UserContextProvider>
    </ChakraProvider>
  );
}

export default App;
