import HttpService from "./HttpService";
import config from '../assets/config.json';


let offersCache = null;

export async function getOffers(userId, reload = false) {

  if (offersCache && !reload) {
    return offersCache;
  }


  try {
    const response = await HttpService.post(config.getOffersApiPath, {
      userId
    });

    offersCache = response.data;
    return response.data;
  } catch(e) {
    throw e;
  }
}
