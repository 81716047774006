import ConfigOptionWrapper from "./ConfigOptionWrapper";
import {Select, Stack, Text} from "@chakra-ui/react";
import config from '../assets/config.json';
import {useUserContext} from "../context/UserContext";
import {useTranslationContext} from "../context/TranslationContext";



function MinPrice({
  onChange
                       }) {
  const { user } = useUserContext();
  const { STRINGS } = useTranslationContext();

  return (
    <ConfigOptionWrapper label={STRINGS.minPrice} helperText={STRINGS.minPriceHelperText}>
    <Select name={"minPrice"} onChange={(e) => onChange(e, 1)} value={user[config.setupOptions.mainKey]?.minPrice || 1}>
        {config.priceOptions.map(minOption => (
          <option key={minOption} value={minOption}>
            ${minOption}
          </option>
        ))}
      </Select>
    </ConfigOptionWrapper>
  )
}

export default MinPrice;
