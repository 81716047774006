import ConfigOptionWrapper from "./ConfigOptionWrapper";
import {useEffect, useState} from 'react';
import {useLoader} from "../utils/hooks";
import {getLocations} from "../services/SetUpService";
import {
  Checkbox,
  CircularProgress,
  CircularProgressLabel,
  Spinner,
  Stack,
  Table, TableContainer, Tbody, Td,
  Th,
  Thead,
  Tr,
  useToast
} from "@chakra-ui/react";
import {useUserContext} from "../context/UserContext";
import config from '../assets/config.json';
import {useTranslationContext} from "../context/TranslationContext";



function Locations({
  onChange
                   }) {
  const [locations, setLocations] = useState([]);
  const loader = useLoader(true);
  const toast = useToast();
  const { user } = useUserContext();
  const { STRINGS } = useTranslationContext();

  async function loadData() {
    try {
      const response = await getLocations();
      setLocations(response);
      loader.stop();
    } catch(e) {
      loader.stop();
      toast({
        status: "error",
        title: "Configuration Error",
        description: STRINGS.errorGettingLocations
      })
    }
  }


  useEffect(() => {
    loadData();
  }, [])


  return (
    <ConfigOptionWrapper label={STRINGS.locations} helperText={STRINGS.selectLocations}>
      {loader.isLoading() && (
        <Spinner alignSelf={"center"} size={"xl"} mt={4}/>
      )}
      {!loader.isLoading() && (
        <TableContainer borderRadius={4} p={2} border={"1px solid lightgray"} borderColor={"gray.300"} overflowY={"scroll"} maxHeight={300}>
          <Table  size={"small"}>
            <Thead>
              <Tr>
                <Th>
                  Active{"__"}
                </Th>
                <Th>
                  Name
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {locations.map(location => (
                <Tr>
                  <Td pl={2} pr={2}>
                    <Checkbox onChange={() => onChange(location)} isChecked={user[config.setupOptions.mainKey]?.locations?.includes(location)} />
                  </Td>
                  <Td>
                    {location}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}

    </ConfigOptionWrapper>
  )
}


export default Locations;
