import {Button, Flex, Text} from "@chakra-ui/react";
import PageWrapper from "../components/PageWrapper";
import {useNavigate} from "react-router-dom";
import {useTranslationContext} from "../context/TranslationContext";

function NotFoundPage() {
  const navigate = useNavigate();
  const { STRINGS } = useTranslationContext();
  return (
    <PageWrapper padding={"32px 32px 48px 32px"}>
      <Flex boxShadow={"lg"} mt={6} maxW={400} width={"100%"} alignSelf={"center"} p={8} borderRadius={8} bg={"white"}
            pt={12} pb={12} flexDirection={"column"}>
        <Text textAlign={"center"} fontSize={"2xl"} fontWeight={"medium"}>
          Whoops!
        </Text>
        <Text textAlign={"center"} fontSize={"2xl"} fontWeight={"medium"}>
          It seems you're lost 🥴
        </Text>
        <Button size={"sm"} mt={4} onClick={() => navigate("/")}>
          Go Back home
        </Button>
      </Flex>
    </PageWrapper>
  )
}

export default NotFoundPage;
