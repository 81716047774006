import PageWrapper from "../components/PageWrapper";
import {Text} from "@chakra-ui/react";
import Configuration from "../components/Configuration";
import {useTranslationContext} from "../context/TranslationContext";


function ConfigurationPage() {
  const { STRINGS } = useTranslationContext();
  return (
    <PageWrapper padding={"32px 32px 48px 32px"}>
      <Text fontSize={"2xl"} fontWeight={"medium"}>
        {STRINGS.configuration}
      </Text>
      <Text>
        {STRINGS.changeYourSystemSettings}
      </Text>
      <Configuration showSteps={false} />
    </PageWrapper>

  )
}

export default ConfigurationPage;
