import {
  Divider,
  Flex,
  List,
  ListItem,
  Stat,
  StatLabel,
  StatNumber,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  Wrap,
} from "@chakra-ui/react";
import HomeWidgetContainer from "./HomeWidgetContainer";
import { useMemo } from "react";
import config from "../assets/config.json";
import moment from "moment-timezone";
import { timeSince } from "../common/functions";
import { useTranslationContext } from "../context/TranslationContext";

function StatsContainer({ blockType, offers }) {
  const { STRINGS } = useTranslationContext();

  const filteredOffers = useMemo(() => {
    return offers
      .filter((item) => item.status === blockType)
      .sort(
        (a, b) =>
          b[config.offerPropertiesMapping.timestamp] -
          a[config.offerPropertiesMapping.timestamp]
      );
  }, [offers, blockType]);
  console.log("filteredOffers: ", filteredOffers);
  return (
    <>
      <HomeWidgetContainer title={STRINGS.topStats}>
        <Stat>
          <StatLabel>
            {STRINGS.blocks}
            {blockType === "got"
              ? STRINGS.accepted.toLowerCase()
              : STRINGS.rejected.toLowerCase()}
            {STRINGS.thisWeek}
          </StatLabel>
          <StatNumber>{filteredOffers.length}</StatNumber>
        </Stat>
        <List mt={4} spacing={4}>
          {filteredOffers.map((offer) => {
            const d = moment(
              offer[config.offerPropertiesMapping.startTime]
            ).format("YYYY-MM-DD h:mm A");
            if (d === "Invalid date") return null;
            return (
              <ListItem position={"relative"}>
                <Flex display={"flex"} alignItems={"center"}>
                  <Text fontSize={"2xl"} fontWeight={"semibold"}>
                    $
                    {config.offerPropertiesMapping.amount.includes(".")
                      ? Number(
                          offer[
                            config.offerPropertiesMapping.amount.split(".")[0]
                          ][
                            config.offerPropertiesMapping.amount.split(".")[1]
                          ] / 100
                        ).toFixed(1)
                      : offer[config.offerPropertiesMapping.amount]}
                  </Text>
                  {/* <Text fontSize={"sm"} ml={4}>
                    {config.offerPropertiesMapping.location.includes(".")
                      ? offer[
                          config.offerPropertiesMapping.location.split(".")[0]
                        ][
                          config.offerPropertiesMapping.location.split(".")[1]
                        ].split(".")[1]
                      : offer[config.offerPropertiesMapping.location].split(
                          "."
                        )[1]}
                  </Text> */}
                </Flex>
                <Text fontSize={"sm"}>
                  {STRINGS.starts}
                  {moment(
                    offer[config.offerPropertiesMapping.startTime]
                  ).format("YYYY-MM-DD h:mm A")}
                </Text>
                <Text fontSize={"xs"}>
                  {STRINGS.deliveryLocation} {offer.metadata["name"]}
                </Text>

                <Tooltip
                  label={new Date(
                    offer[config.offerPropertiesMapping.timestamp]
                  ).toString()}
                >
                  <Text
                    color={"gray.500"}
                    fontSize={"xs"}
                    position={"absolute"}
                    bottom={1}
                    right={0}
                  >
                    {timeSince(
                      new Date(offer[config.offerPropertiesMapping.timestamp])
                    )}
                  </Text>
                </Tooltip>
                <Divider />
              </ListItem>
            );
          })}
        </List>
      </HomeWidgetContainer>
    </>
  );
}

export default StatsContainer;
