import {Flex, Text} from "@chakra-ui/react";
import config from '../assets/config.json'
import {useUserContext} from "../context/UserContext";


function PageWrapper({ children, padding }) {
  const { user } = useUserContext();
  return (
    <Flex maxW={900} margin={"0 auto"} flexDirection={"column"} p={padding || 2}>
      {children}
        <Text mt={4} textAlign={"center"} color={"gray"}>
          © 2024 {config.appName}. All rights reserved. {user && `Logged in as ${user.name}`}
        </Text>
    </Flex>
  )
}

export default PageWrapper;
