import HttpService from "./HttpService";
import config from '../assets/config.json'


let locationCache = null;

export async function validateCredentials(form) {
  try {
    const response = await HttpService.post("/" + config.validatePath, form);
    return response.data;
  } catch(e) {
    throw e;
  }
}

export async function getLocations(reload = false) {

  if (locationCache && !reload) {
    return locationCache;
  }
  try {
    const response = await HttpService.get(config.getLocationsPath);
    locationCache = response.data;
    return response.data;
  } catch(e) {
    throw e;
  }
}
