import HttpService from './HttpService'
import config from '../assets/config.json'

export async function getUser() {
  try {
    const response = await HttpService.get("/v2/user");
    return response.data;
  } catch(e) {
    throw e;
  }
}
export async function patchUser({ userId, body }) {
  try {
    const response = await HttpService.patch("/user/web", {
      userId,
      body
    });
    return response.data;
  } catch(e) {
    throw e;
  }
}

export async function requestForgotPassword(phoneNumber) {
  try {
    const response = await HttpService.get("/forgot-password?phoneNumber=" + phoneNumber + "&brand=" + config.brand)
    return response.data;
  } catch(e) {
    throw e;
  }
}
export async function verifyCode(phoneNumber, code) {
  try {
    const response = await HttpService.post("/forgot-password", {
      phoneNumber,
      verificationCode: code
    })
    return response.data;
  } catch(e) {
    throw e;
  }
}

export async function confirmChangePassword(phoneNumber, code, newPassword) {
  try {
    const response = await HttpService.post("/forgot-password", {
      phoneNumber,
      verificationCode: code,
      password: newPassword,
      ghostApp: "LaOrca"
    })
    return response.data;
  } catch(e) {
    throw e;
  }
}

export async function getReferralCodes() {
  try {
    const response = await HttpService.get("/referral");
    return response.data;
  } catch(e) {
    throw e;
  }
}
