import ConfigOptionWrapper from "./ConfigOptionWrapper";
import {Select} from "@chakra-ui/react";
import config from '../assets/config.json';
import {useUserContext} from "../context/UserContext";
import {useTranslationContext} from "../context/TranslationContext";


function TimeBuffer({
  onChange
                    }) {
  const { user } = useUserContext();
  const { STRINGS } = useTranslationContext();

  return (
    <ConfigOptionWrapper label={STRINGS.timeBuffer} helperText={STRINGS.selectTimeBuffer}>
      <Select name={"timeBuffer"} onChange={(e) => onChange(e, 1)} value={user[config.setupOptions.mainKey]?.timeBuffer || 0}>
        {config.timeBufferOptions.map(time => (
          <option value={time}>
            {time} minutes
          </option>
        ))}
      </Select>

    </ConfigOptionWrapper>
  )
}

export default TimeBuffer;
