import React from 'react';
import {Button, Spinner, Text, useToast} from "@chakra-ui/react";
import {PaymentElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {loadStripe} from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import {useLoader} from "../utils/hooks";
import {useTranslationContext} from "../context/TranslationContext";

const stripePromise = loadStripe( 'pk_live_51Jphp4HyCJ4MRtI6WYcMGFAD4k0nDgqpxfhGoJkJmxscNTik9sPTcAbYT4Ctoap3OQ4uLjtUQIV5Zrmyc4fFYnML00FMx4ovOy');

function Form({ paymentIntent, total }) {
  const { STRINGS } = useTranslationContext();
  const stripe = useStripe();
  const elements = useElements();
  const loader = useLoader(false);
  const toast = useToast();

  async function handleSubmit(e) {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    loader.start();

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: window.origin + "/billing?transaction-complete=true",
      },
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      toast({
        status: "error",
        title: "Payment Error",
        description: result.error.message
      });
      loader.stop();
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  }

  return (

  <>
    <Text mb={4} fontSize={"2xl"} fontWeight={"semibold"}>
      {STRINGS.makePayment}
    </Text>
    {!stripe && (
      <Spinner alignSelf={"center"}/>
    )}
    <PaymentElement />
    <Text mt={4} alignSelf={"flex-end"}>
      {STRINGS.totalCost}<Text fontSize={"3xl"} fontWeight={"bold"} as={"span"}>${total}</Text>
    </Text>
    <Button isLoading={loader.isLoading()} isDisabled={loader.isLoading()} onClick={handleSubmit} mt={4}>
      {STRINGS.pay}
    </Button>
  </>
  );
}

function  PaymentForm({ paymentIntent, total }) {
  return (
    <Elements options={{ clientSecret: paymentIntent }} stripe={stripePromise}>
      <Form paymentIntent={paymentIntent} total={total} />
    </Elements>
  );
}

export default PaymentForm;
