import {Button, Flex, Box,IconButton, MenuIcon, Stack, Text, useMediaQuery} from "@chakra-ui/react";
import { useNavigate } from 'react-router-dom'
import config from '../assets/config.json';
import {BiLogOut, BiMenu} from "react-icons/bi";
import {Auth} from "aws-amplify";
import Sidebar from './Sidebar';
import {useState} from "react";
import {IoMdSettings} from "react-icons/io";

function NavBar() {
  const navigate = useNavigate();
  const [isLargerThan1280] = useMediaQuery('(min-width: 800px)')
  const [showDrawer, setShowDrawer] = useState(false);

  async function onLogOutClick() {
    await Auth.signOut();
    localStorage.clear();
    window.location.href = "/login";
  }

  return (
    <Flex justifyContent={isLargerThan1280 ? "inherit" : "space-between"} boxShadow={"sm"} alignItems={"center"} bg={"white"} pl={8}  pt={4} pb={4} pr={8}>
      <Sidebar onClose={() => setShowDrawer(false)} open={showDrawer} />
      <Text cursor={"pointer"} onClick={() => navigate("/home")} color={"black"} fontSize={"lg"} fontWeight={"bold"}>
        {config.appName} <Text as={"span"} fontSize={"xs"}>v{config.appVersion}</Text>
      </Text>
      {isLargerThan1280 && (
        <>
          <Flex flex={"1"} alignItems={"center"} justifyContent={"space-between"}>
            <Stack spacing={4} ml={4} direction={"row"}>
              {config.navOptions.map(navOption => (
                <Box _hover={{
                  background: "gray.50"
                }}   borderBottomWidth={"2px"} borderBottomColor={window.location.pathname.includes(navOption.path) ? config.inputOutlineColor : "white"}>
                  <Button size={"sm"} colorScheme={"blackAlpha"} variant={"subtle"}  onClick={() => navigate(navOption.path)}>
                    {navOption.label}
                  </Button>
                </Box>
              ))}
            </Stack>

            <Stack direction={"row"}>
            <IconButton color={"gray.600"} onClick={() => navigate("/preferences")} colorScheme={"gray"} variant={"ghost"}>
              <IoMdSettings  fontSize={"20px"} />
            </IconButton>
            <IconButton onClick={onLogOutClick} colorScheme={"gray"} variant={"ghost"}>
              <BiLogOut fontSize={"20px"} />
            </IconButton>
            </Stack>

          </Flex>
        </>
      )}
      {!isLargerThan1280 && (
          <IconButton onClick={() => setShowDrawer(true)} colorScheme={"gray"} variant={"ghost"}>
            <BiMenu fontSize={"20px"} />
          </IconButton>
      )}

    </Flex>
  )
}

export default NavBar;
