import {Button, PinInput, PinInputField, Stack, Text} from "@chakra-ui/react";
import {useState} from "react";
import { Auth } from 'aws-amplify';
import {useTranslationContext} from "../context/TranslationContext";


function CodeVerification({ phoneNumber }) {
  const [code, setCode ] = useState("");
  const { STRINGS } = useTranslationContext();

  async function confirmCode() {
    try {
      await Auth.confirmSignUp(phoneNumber, code);
    } catch (error) {
      console.log('error confirming sign up', error);
    }
  }
  return (
    <>
      <Text textAlign={"center"} fontSize={"xl"} fontWeight={"semibold"}>Confirm your account</Text>
      <Text textAlign={"center"}ssssssss>
        {STRINGS.weHaveSent}
      </Text>
      <Stack direction={"row"} mt={4} alignSelf={"center"}>
        <PinInput value={code} onChange={setCode} otp>
          <PinInputField />
          <PinInputField />
          <PinInputField />
          <PinInputField />
          <PinInputField />
          <PinInputField />
        </PinInput>
      </Stack>
      <Button disabled={code.length < 6} mt={8}>
        Confirm
      </Button>
    </>
  );
}

export default CodeVerification;
