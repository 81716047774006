export const STRINGS_ES = {
  phoneNumber: "Número de teléfono",
  password: "Contraseña",
  loginToYourAccount: "Inicia sesión",
  login: "Iniciar sesión",
  dontHaveAnAccount: "¿No tienes una cuenta? ",
  signUp: "Inscribirse",
  havingTroubleLoggingIn: "¿Tienes problemas para iniciar sesión? ",
  clickHereToResetPassword: "Pincha aquí para restaurar tu contraseña",
  confirmPassword: "Confirmar contraseña",
  name: "Nombre",
  alreadyHaveAnAccount: "¿Ya tienes una cuenta? ",
  startSetUp: "Configura tu cuenta",
  enterAccountCredentials:
    "Ingrese las credenciales de su cuenta a continuación",
  continue: "Continuar",
  weHaveSent:
    "Te hemos envíado un código por mensaje de texto. Por favor ingrésalo a continuación",
  youAlreadyRegistered: "Ya te has registrado... por favor inicia sesión.",
  youHaveBeenInvitedTo: "Te han invitado a ",
  loadingYourInvitation: "Cargando tu invitación...",
  billing: "Facturación",
  payForService: "Paga por tu servicio aquí",
  accountStatus: "Estado de la cuenta",
  yourLastActivation: "Su última fecha de activación es ",
  yourAccountIs: "Tu cuenta es ",
  expired: "EXPIRADO",
  active: "ACTIVO",
  costBreakdown: "Desglose de los costos",
  serviceCost: "Costo del servicio",
  transactionFee: "Tarifa de transacción",
  totalCost: "Costo total: ",
  proceedToPayment: "Proceder al pago",
  hiThere: "¡Hola! 👋",
  weAreSorryToHear:
    'Lamentamos saber que su servicio ha expirado.{"\\n"}Esperamos que pueda resumir su suscripción pronto!',
  ifYouHaveQuestions:
    "Si tiene alguna pregunta o inquietud, no dude en comunicarse con nosotros.",
  makePayment: "Hacer el pago",
  pay: "Pagar",
  configuration: "Configuración",
  changeYourSystemSettings: "Cambiar la configuración de su sistema",
  successfullyUpdatedConfig: "Configuración actualizada con éxito",
  unableUpdateConfig: "No se pudo guardar la configuración",
  notAbleFinalizeConfig: "No se ha podido finalizar la configuración",
  back: "Atrás",
  minBlockHour: "📦 Horas mínimas por bloque",
  chooseMinAmountHours:
    "Elija la cantidad mínima de horas por bloque que quisieras aceptar.",
  startTime: "🕕 Hora de inicio",
  selectHourStart:
    "Selecciona la hora en la que quisieras comenzar a trabajar.",
  endTime: "🕥 Hora de finalización",
  selectHourStop:
    "Selecciona la hora en la que quisieras terminar de trabajar.",
  timeBuffer: "⏰ Tiempo de antelación",
  selectTimeBuffer:
    "Selecciona la cantidad mínima de tiempo que necesitas antes de comenzar el bloque.",
  deliveryLocation: "🚚 Lugar de entrega",
  enterDeliveryLocations:
    "Ingrese las ubicaciones donde desea entregar paquetes. Recuerde escribirlos correctamente o no tomaremos ningún bloque por usted.",
  minPrice: "💰 Precio minimo por bloque",
  minPriceHelperText:
    "Elija el precio minimo por bloque que quisieras aceptar.",
  errorGettingLocations:
    "Error al obtener ubicaciones: actualice para volver a intentarlo",
  locations: "📍 Ubicaciones",
  selectLocations:
    "Seleccione las ubicaciones en las que le gustaría trabajar.",
  home: "Inicio",
  viewYourStats: "Consulta tus estadísticas y controla tu servicio",
  accepted: "Aceptado",
  rejected: "Rechazado",
  unableToChangeStatus: "No se puede cambiar el estado del sistema",
  systemControl: "Control de sistema",
  pressPowerButton:
    "Presione el botón de encendido para encender o apagar el sistema",
  status: "Estado: ",
  on: "Encendido",
  off: "Apagado",
  topStats: "Estadísticas principales",
  blocks: "Bloques ",
  thisWeek: " esta semana",
  starts: "Comienza - ",
  reason: "Razón - ",
  locationAlreadyExists: "Ya tienes este lugar de entrega.",
  preferences: "Preferencias",
  changeUserPreferences: "Cambiar tus preferencias",
  contactInfo: "Datos de contacto",
  contactMeAt: "Contáctame a",
  thisIsWhereWeNotifyYou:
    "Aqui te notificaremos cuando te agarramos un bloque.",
  update: "Actualizar",
  incorrectUsernameOrPassword: "Nombre de usuario o contraseña incorrecta",
  errorLoadingInvites: "Error al cargar invitaciones",
  inviteSentSuccessfully: "Invitación enviada con éxito",
  errorSendingInvite: "Error al enviar invitación",
  referAFriend: "Referir a un amigo",
  startYourWeekWithAFriend: "¡Comienza tu semana con un amigo!",
  ifTheyPay: "Si hacen su pago, ",
  getAWeekFree: " consigue una semana gratis.",
  you: "Tú",
  itIsThatSimple: "Es así de simple.",
  sendInvite: "Enviar Invitación",
  yourFriendPhoneNumber: "El número de teléfono de tu amigo",
  previewMessage: "Vista previa del mensaje",
  weAreExcitedMessage:
    " ¡Hola!\nEstamos muy emocionados de que se una a nosotros en APP_NAME!\nUSERNAME te ha invitado a unirte a nosotros. Por favor visite este enlace para registrarse.",
  send: "Enviar",
  invitesYouSent: "Invitaciones que has enviado",
  youInvited: "Has invitado a",
  haveTheyPaid: "Han pagado",
  resetYourPassword: "Restablecer su contraseña",
  enterVerificationCode: "Introduce el código de verificación",
  enterNewPassword: "Ingrese nueva contraseña",
  changePassword: "Cambiar contraseña",
  distanceToLocation: "📍 Ubicacion",
  distanceToLocationHelperText: "Escoga lugar para recoger paquetes",
  pickupLocation: "Donde recoger",
  maxMiles: "Máximas millas para conducir",
};
